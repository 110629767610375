@media print {
  #printArea * {
    display: block !important; /* Forces block rendering */
  }

  .flex {
    display: block !important; /* Converts flexbox to block */
  }

  .items-center {
    align-items: flex-start !important; /* Ensures elements don't overlap */
  }

  .mr-2 {
    margin-right: 4px !important; /* Adjust spacing if needed */
  }
}
